import * as LOCALS from './constants';
import { ILocals } from './types';

const zagrywkiLocals: ILocals = {
  [LOCALS.SELECT_PLACE_TEXT]: 'Wybierz miejsce',
  [LOCALS.SELECT_PLAYERS_TEXT]: 'Wybierz liczbę graczy',
  [LOCALS.REMEMBERS_TEXT]:
    'Pamiętaj żeby pojawić się w Zagrywkach przynajmniej 20 min. przed czasem!',
  [LOCALS.RESERVATION_TEXT]: 'Podaj dane rezerwacji',
  [LOCALS.INTRO_RESERVATION_TEXT]: 'Co Was interesuje?',
};

export default zagrywkiLocals;
